import { ReactNode } from 'react'
import { FocusOn } from 'react-focus-on'
import { motion } from 'framer-motion'
import { rgba } from 'polished'
import styled, { css } from 'styled-components'

import { FlowControl } from 'bl-flows-core'

import { colors } from '../../constants/colors'
import { CloseIcon } from '../../elements/Icons/CloseIcon'
import { PartialBookingEngine } from '../../styles/types'
import { media } from '../../utils/media'

const Content = styled.div<{
  themeStyle?: PartialBookingEngine['cart']['mobileCart']
}>`
  background: ${props => props?.themeStyle?.background ?? colors.white};
  color: ${props => props?.themeStyle?.textColor};
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: ${({ theme }) =>
    `${theme.spacing[2.5]} ${theme.spacing[1.5]} ${theme.spacing[1.5]}`};

  ${media.bmd(css`
    height: auto;
    max-height: 80vh;
    margin-top: ${({ theme }) => theme.spacing[4]};
  `)}
`

const MobileContainer = styled(motion.div)<{
  $themeStyle?: PartialBookingEngine['cart']['mobileCart']
}>`
  background: ${props => props?.$themeStyle?.background ?? 'white'};
  bottom: 0;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 80%;
  left: 0;
  padding: ${({ theme }) => theme.spacing[0.5]};
  position: fixed;
  width: 100%;
  transform: translateY(100%);
  z-index: 2;

  ${media.bmd(css`
    display: none;
  `)}
`

const CloseButton = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  padding: 20px;
`

const Backdrop = styled(motion.div)<{
  $themeStyle?: PartialBookingEngine['cart']['mobileCart']
}>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${props =>
    rgba(props?.$themeStyle?.backdropColor ?? colors.black, 0.25)};
  backdrop-filter: blur(3px);
  opacity: 0;
  z-index: 2;

  ${media.bmd(css`
    display: none;
  `)}
`

type MobileCartType = {
  control: FlowControl
  children: ReactNode
  themeStyle: PartialBookingEngine['cart']['mobileCart']
}

const MobileCart = ({ control, children, themeStyle }: MobileCartType) => {
  const closeCart = () =>
    control.flow.setState({
      mobileCart: {
        ...control.flow.state?.mobileCart,
        isOpen: false,
      },
    })

  const variants = {
    open: { y: 0, display: 'block' },
    closed: { y: '100%', transitionEnd: { display: 'none' } },
  }
  const backdropVariants = {
    open: { opacity: 1 },
    closed: { opacity: 0 },
  }

  const isOpen = control.flow.state?.mobileCart?.isOpen

  return (
    <>
      {isOpen && (
        <Backdrop
          variants={backdropVariants}
          animate={isOpen ? 'open' : 'closed'}
          $themeStyle={themeStyle}
        />
      )}
      <FocusOn
        enabled={isOpen}
        returnFocus
        onEscapeKey={closeCart}
        onClickOutside={closeCart}
      >
        <MobileContainer
          variants={variants}
          initial="closed"
          animate={isOpen ? 'open' : 'closed'}
          transition={{ type: 'spring', bounce: 0, duration: 0.95 }}
          $themeStyle={themeStyle}
        >
          <Content themeStyle={themeStyle}>{children}</Content>
          <CloseButton onClick={closeCart} type="button">
            <CloseIcon
              style={{ width: '12px' }}
              color={themeStyle?.closeIconColor}
            />
          </CloseButton>
        </MobileContainer>
      </FocusOn>
    </>
  )
}

export default MobileCart
