import { useEffect } from 'react'

import { useCartContext } from 'bl-common/src/hooks/useCartContext'
import { FlowControl } from 'bl-flows-core'

import { globalBookingMessages } from '../messages'

export const useRequireCartItems = (
  control: FlowControl,
  maximumAllowedScreenIndex = 0,
  resetState: any = {}
) => {
  const { cart, loading, resetCart } = useCartContext()

  const checkForCartItems = async () => {
    const missingCartItems = cart?.items?.length === 0
    if (!missingCartItems) {
      return
    }
    await control.confirm(
      control.context.t(globalBookingMessages.text.emptyCartSubtitle),
      {
        title: control.context.t(globalBookingMessages.text.emptyCartTitle),
        confirmLabel: control.context.t(globalBookingMessages.buttons.continue),
      }
    )
    await resetCart()

    control.reset({
      resetState,
    })
  }

  useEffect(() => {
    const isPastFirstScreen =
      control.currentScreenIndex > maximumAllowedScreenIndex
    const shouldCheckForItems =
      isPastFirstScreen && !loading && cart && Array.isArray(cart.items)

    if (shouldCheckForItems) {
      checkForCartItems()
    }
  }, [cart, loading, control.currentScreenIndex])
}
