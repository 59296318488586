import groupBy from 'lodash/groupBy'

import { CartItemType } from 'bl-graphql'
import { formatDateInUTC } from 'bl-utils/src/formatting/formatDate'
import { isElementIncludedInArray } from 'bl-utils/src/isElementIncludedInArray'
import {
  ActivityBasedProductIds,
  HB_PRODUCT_IDS,
} from 'bl-utils/src/ProductIds'

const combineMassages = massages => {
  if (!massages) {
    return []
  }
  const combinedMassages = []
  massages.filter(m => {
    const isDuplicate = combinedMassages.find(
      element =>
        element.productId === m.productId &&
        element.meta.arrivalTime === m.meta.arrivalTime
    )

    if (!isDuplicate) {
      combinedMassages.push({ ...m, qty: 1 })

      return true
    } else {
      isDuplicate.qty += 1
      return false
    }
  })
  return combinedMassages
}

export const orderRooms = cart => {
  const roomsInCart =
    cart?.items?.filter(item => item.type === CartItemType.Hotel) || []

  const productsInCart = groupBy(
    cart?.items?.filter(item => item.type === CartItemType.Item) || [],
    'meta.bookingRef'
  )

  const activitiesInCart = groupBy(
    cart.items
      ?.filter(item =>
        isElementIncludedInArray(ActivityBasedProductIds, item.productId)
      )
      ?.map(item => {
        const date =
          item?.meta?.arrivalTime &&
          formatDateInUTC(item.meta.arrivalTime, 'dd MMM')
        const time =
          item?.meta?.arrivalTime &&
          formatDateInUTC(item.meta.arrivalTime, 'HH:mm')
        return {
          ...item,
          roomId: item.id.split('-')[0],
          date,
          time,
        }
      })
      .filter(item => item.type === CartItemType.Activity) || [],
    'roomId'
  )
  const restaurantReservationsInCart = groupBy(
    cart?.items
      ?.map(item => {
        const date =
          item?.meta?.arrivalTime &&
          formatDateInUTC(item?.meta?.arrivalTime, 'dd MMM')
        const time =
          item?.meta?.arrivalTime &&
          formatDateInUTC(item?.meta?.arrivalTime, 'HH:mm')
        return {
          ...item,
          roomId: item.id.split('-')[0],
          date,
          time,
        }
      })
      .filter(item => item.type === CartItemType.Restaurant) || [],
    'roomId'
  )

  const combinedMassages = combineMassages(
    cart?.items.filter(item => item.type === CartItemType.Massage)
  )

  const massagesInCart = groupBy(
    combinedMassages?.map(item => {
      const date =
        item?.meta?.arrivalTime &&
        formatDateInUTC(item?.meta?.arrivalTime, 'dd MMM')
      const time =
        item?.meta?.arrivalTime &&
        formatDateInUTC(item?.meta?.arrivalTime, 'HH:mm')

      return {
        ...item,
        roomId: item.id.split('-')[0],
        date,
        time,
      }
    }) || [],
    'roomId'
  )

  const privateTransfersInCart = groupBy(
    cart?.items
      .filter(item => item.type === CartItemType.PrivateTransfer)
      ?.map(item => {
        const date =
          item?.meta?.arrivalTime &&
          formatDateInUTC(item?.meta?.arrivalTime, 'dd MMM')
        const time =
          item?.meta?.arrivalTime &&
          formatDateInUTC(item?.meta?.arrivalTime, 'HH:mm')

        return {
          ...item,
          roomId: item.id.split('-')[0],
          date,
          time,
        }
      }) || [],
    'roomId'
  )

  const highlandBaseTransfersInCart = groupBy(
    cart?.items
      .filter(
        item =>
          item.productId === HB_PRODUCT_IDS.TransferSkjolHb ||
          item.productId === HB_PRODUCT_IDS.TransferHbSkjol ||
          item.productId === HB_PRODUCT_IDS.TransferRvkHb ||
          item.productId === HB_PRODUCT_IDS.TransferHbRvk
      )
      ?.map(item => {
        const date =
          item?.meta?.arrivalTime &&
          formatDateInUTC(item?.meta?.arrivalTime, 'dd MMM')
        const time =
          item?.meta?.arrivalTime &&
          formatDateInUTC(item?.meta?.arrivalTime, 'HH:mm')

        return {
          ...item,
          roomId: item.id.split('-')[0],
          date,
          time,
        }
      }) || [],
    'roomId'
  )

  const orderedRooms = roomsInCart.reduce((acc, room) => {
    const products = productsInCart[room.id] || []
    const massages = massagesInCart[room.id] || []
    const restaurantReservations = restaurantReservationsInCart[room.id] || []
    const activities = activitiesInCart[room.id] || []
    const privateTransfers = privateTransfersInCart[room.id] || []
    const highlandBaseTransfers = highlandBaseTransfersInCart[room.id] || []

    return [
      ...acc,
      room,
      ...products,
      ...activities,
      ...restaurantReservations,
      ...massages,
      ...privateTransfers,
      ...highlandBaseTransfers,
    ]
  }, [])

  return orderedRooms
}
