import { useContext, useState } from 'react'
import Portal from '@reach/portal'
import { AnimatePresence, motion } from 'framer-motion'
import styled, { css } from 'styled-components'

import { FlowControl } from 'bl-flows-core'

import { CartCard } from '../../booking/CartCard/CartCard'
import { colors } from '../../constants/colors'
import { CurrencyContext } from '../../context/Currency/CurrencyProvider'
import { CurrencySelector } from '../../elements/CurrencySelector'
import { Spinner } from '../../elements/Spinner'
import { SpinnerWrapper } from '../../elements/SpinnerWrapper'
import { Type } from '../../elements/Typography/Typography'
import { useCartContext } from '../../hooks/useCartContext'
import { PartialBookingEngine } from '../../styles/types'
import { media } from '../../utils/media'
import MobileCart from './MobileFlowCart'

const Container = styled.div`
  display: none;

  ${media.bmd(css`
    display: flex;
    width: 100%;
    flex-direction: column;
  `)}
`

const Content = styled.div<{
  themeStyle?: PartialBookingEngine['cart']
}>`
  background: ${props => props?.themeStyle?.backgroundColor ?? colors.white};
  color: ${props => props?.themeStyle?.textColor ?? colors.midGrey};
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: ${({ theme }) =>
    `${theme.spacing[2.5]} ${theme.spacing[1.5]} ${theme.spacing[1.5]}`};

  border-radius: ${props => props.themeStyle?.borderRadius ?? 0}px;

  ${media.bmd(css`
    height: auto;
    margin: ${({ theme }) => `${theme.spacing[4]} 0`};
  `)};
`

const Total = styled.div<{
  themeStyle?: PartialBookingEngine['cart']
}>`
  background: ${props => props?.themeStyle?.backgroundColor ?? colors.white};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: ${({ theme }) => `${theme.spacing[1.5]} 0 0`};
  position: relative;

  ::before {
    content: '';
    border-top: 1px solid
      ${props => props.themeStyle?.borderColor ?? colors.midGrey};
    left: 0;
    opacity: 0.25;
    position: absolute;
    right: 0;
    top: 1px;
  }
`

const Tickets = styled.div<{
  themeStyle?: PartialBookingEngine['cart']
}>`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  padding: ${({ theme }) => `${theme.spacing[1.5]} 0`};
  position: relative;
  flex: 1;

  ::before {
    content: '';
    border-top: 1px solid
      ${props => props.themeStyle?.borderColor ?? colors.midGrey};
    left: 0;
    opacity: 0.25;
    position: absolute;
    right: 0;
    top: 1px;
  }

  ${media.md(css`
    min-height: 240px;
    padding: ${({ theme }) => `${theme.spacing[1]} 0`};
  `)}
`

const ControlsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing[1.5]};
  position: relative;

  ${media.md(css`
    margin-bottom: ${({ theme }) => theme.spacing[1]};
  `)}
`

const EditButton = styled.button<{
  themeStyle?: PartialBookingEngine['cart']
}>`
  color: ${props => props?.themeStyle?.buttonColor ?? colors.deepBlue};
  background: ${props => props?.themeStyle?.backgroundColor ?? colors.white};
  cursor: pointer;
`

interface CartItem {
  id: string
  key: string
  title: string
  time: string
  type: string
  description: string
  price: number
  editing: boolean
  canDelete: boolean
  onDelete: () => void
}

interface FlowCartProps {
  title: string
  subTitle?: string
  editCart?: string
  cancelEditCart?: string
  deleteLabel?: string
  totalText: string
  iskDisclaimer?: string
  items: CartItem[]
  control: FlowControl
  isDeletingItem?: boolean
  themeStyle?: PartialBookingEngine['cart']
  isSpaBooking?: boolean
  giftCardsText?: string
  displayFractionalPrices?: boolean
  hideCurrencyChange?: boolean
}

export const FlowCart: React.FC<FlowCartProps> = ({
  title,
  items = [],
  totalText,
  editCart,
  subTitle,
  iskDisclaimer,
  deleteLabel,
  control,
  isDeletingItem,
  themeStyle,
  isSpaBooking,
  giftCardsText,
  displayFractionalPrices,
  hideCurrencyChange = false,
}) => {
  const { currency } = useContext(CurrencyContext)
  const [editing, setEditing] = useState(false)

  const { loading: isCartLoading } = useCartContext()

  const variants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.15,
        delayChildren: 0.2,
      },
    },
  }

  const onEditClick = () => {
    setEditing(!editing)
  }

  const hasItemToEdit = items.some(item => item.canDelete)

  const renderCart = (
    mobile = false,
    themeStyle: PartialBookingEngine['cart'] = undefined
  ) => (
    <>
      <Type preset="headlineMedium" bottom={subTitle ? 0.5 : 2}>
        {title}
      </Type>
      {!!subTitle && (
        <Type preset="textLarge" weight="light" bottom={2}>
          {subTitle}
        </Type>
      )}
      <ControlsWrapper>
        {!!editCart && hasItemToEdit ? (
          <EditButton
            onClick={onEditClick}
            type="button"
            themeStyle={themeStyle}
          >
            <Type preset="labelSmall" weight="bold" case="uppercase">
              {editCart}
            </Type>
          </EditButton>
        ) : (
          <span />
        )}
        {!hideCurrencyChange && (
          <CurrencySelector
            id={`currency-selector${mobile ? '-mobile' : ''}`}
            minimal
            verticalPosition="bottom"
            horizontalPosition="right"
            positionRelativeTo={{ vertical: 'outside' }}
            offset={{ y: 5 }}
            themeStyle={themeStyle}
          />
        )}
      </ControlsWrapper>
      <Tickets themeStyle={themeStyle}>
        {isDeletingItem || isCartLoading ? (
          <SpinnerWrapper verticalCenter style={{ flexGrow: 1 }}>
            <Spinner
              shouldAnimate
              color={themeStyle?.spinnerColor ?? colors.deepBlue}
            />
          </SpinnerWrapper>
        ) : (
          <>
            {items.length > 0 && (
              <motion.ul
                layout
                variants={variants}
                animate="show"
                initial="hidden"
                style={{ height: '100%' }}
              >
                <AnimatePresence>
                  {items.map(item => (
                    <motion.li
                      layout
                      key={item.key}
                      variants={{
                        hidden: { opacity: 0 },
                        show: { opacity: 1 },
                      }}
                      exit={{ opacity: 0, height: 0 }}
                    >
                      <CartCard
                        {...item}
                        editing={editing}
                        deleteLabel={deleteLabel}
                        isSpaBooking={isSpaBooking}
                        displayFractionalPrices={displayFractionalPrices}
                      />
                    </motion.li>
                  ))}
                </AnimatePresence>
              </motion.ul>
            )}
          </>
        )}
      </Tickets>
      <Total themeStyle={themeStyle}>
        <Type preset="text" right={{ xs: 0.5 }} inline>
          {giftCardsText && <div>{giftCardsText}</div>}
        </Type>
        <div>
          <Type size={{ xs: 18 }} weight="bold" right={{ xs: 0.5 }} inline bold>
            {totalText}
          </Type>
        </div>
        {currency !== 'ISK' && (
          <Type preset="textSmall" top={0.5}>
            {iskDisclaimer}
          </Type>
        )}
      </Total>
    </>
  )

  return (
    <>
      <Container>
        <Content themeStyle={themeStyle}>
          {renderCart(false, themeStyle)}
        </Content>
      </Container>
      <Portal>
        <MobileCart control={control} themeStyle={themeStyle?.mobileCart}>
          {renderCart(true, themeStyle)}
        </MobileCart>
      </Portal>
    </>
  )
}
