import { NextRouter, useRouter } from 'next/router'

const useBookingStep = (router: NextRouter) => {
  const {
    query: { step, screenId },
  } = router

  return { step: step as string, screenId: screenId as string }
}

type UseNextFlowRouter = (
  flowBasePath: string
) => [
  string,
  (
    newStep: string,
    replace?: boolean,
    screenId?: string,
    queryParams?: Record<string, string>
  ) => void,
  (query: Record<string, string>, currentScreenId: string) => void,
]

export const useNextFlowRouter: UseNextFlowRouter = flowBasePath => {
  const router = useRouter()

  const { step: currentStep, screenId } = useBookingStep(router)

  const updateQueryParams = (
    queryParams: Record<string, string>,
    currentScreenId
  ) => {
    if (!router.asPath.startsWith(flowBasePath)) {
      // Navigating somewhere else, do nothing
      return
    }
    // Get existing query params and create a new dictionary object
    const existingParams = new URLSearchParams(window.location.search)
    const dict: { [key: string]: string } = {}

    // Copy existing query params into dictionary object
    existingParams.forEach((value, key) => {
      dict[key] = value
    })

    // Merge existing and new query params and remove empty values
    const mergedDict = { ...dict, ...queryParams }
    const searchParams = new URLSearchParams()

    for (const [key, value] of Object.entries(mergedDict)) {
      if (typeof value === 'string' && value.trim() !== '') {
        searchParams.set(key, value)
      }
    }

    const newSearchString = `?${searchParams.toString()}`

    // Update URL search string if it has changed
    // In some cases the router is behind the current flow screen id,
    // in those cases we don't want to update the URL.
    // Otherwise we could run into an issue where the user
    // jumps back to the previous URL.
    if (
      window.location.search !== newSearchString &&
      screenId === currentScreenId
    ) {
      const as = `${window.location.pathname}${newSearchString}`
      router.replace(
        {
          pathname: router.route,
          query: { ...router.query, ...queryParams },
        },
        as,
        {
          shallow: true,
        }
      )
    }
  }

  const updateStep = (
    newStep: string,
    replace = false,
    screenId = '',
    queryParams: Record<string, string> = {}
  ) => {
    if (!router.asPath.startsWith(flowBasePath)) {
      // Navigating somewhere else, do nothing
      return
    }

    if (newStep === currentStep || !currentStep) {
      replace = true
    }

    // Build URLs for push/replace
    const currentQueryParams = new URLSearchParams(window.location.search)

    const dict: { [key: string]: string } = {}

    // Copy existing query params into dictionary object
    currentQueryParams.forEach((value, key) => {
      dict[key] = value
    })

    // Merge existing and new query params and remove empty values
    const mergedDict = { ...dict, ...queryParams }
    const searchParams = new URLSearchParams()

    for (const [key, value] of Object.entries(mergedDict)) {
      if (typeof value === 'string' && value.trim() !== '') {
        searchParams.set(key, value)
      }
    }

    const as = `${flowBasePath}/${newStep}${
      searchParams.toString() ? '?' : ''
    }${searchParams.toString()}`
    // Some steps have have dynamic parts, e.g. {property}/packages
    // we need to remove the dynamic part to update the step with the correct name.
    const newStepUpdated = newStep.split('/').pop()

    // Update router state with new step
    const query = {
      ...router.query,
      ...queryParams,
      step: newStepUpdated,
      screenId,
    }
    const routeOptions = { pathname: router.route, query }
    const navigationOptions = { shallow: true }

    if (replace) {
      router.replace(routeOptions, as, navigationOptions)
    } else {
      router.push(routeOptions, as, navigationOptions)
    }
  }

  return [currentStep, updateStep, updateQueryParams]
}
